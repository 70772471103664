@font-face {
  font-family: "FE-FONT";
  src: url(src/assets/ace-assets/fontes/FE-FONT.TTF) format("truetype");
  font-weight: 300;
}
/* Container holding the image and the text */
.containerplaca {
  position: relative;
  color: white;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered2 {
  position: absolute;
  font-family: FE-FONT;
  font-size: 100px;
  letter-spacing: 13px;
  top: 22%;
  left: 13%;
}

.centered {
  position: absolute;
  font-family: FE-FONT;
  font-size: 96px;
  letter-spacing: 13px;
  top: 22%;
  left: 9%;
}

.centeredMoto2 {
  position: absolute;
  top: 22%;
  left: 22%;
}

.centeredMoto {
  position: absolute;
  top: 22%;
  left: 12%;
}

.motoLetras {
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: FE-FONT;
  letter-spacing: 15px;
  font-size: 90px;
  line-height: 1;
}

.sk-wave {
  margin-top: 2px;
  margin-bottom: 0px;
}

.animate-show-hide.ng-hide {
  opacity: 0;
}

.animate-show-hide.ng-hide-add,
.animate-show-hide.ng-hide-remove {
  transition: all linear 0.5s;
}

.check-element {
  border: 1px solid black;
  opacity: 1;
  padding: 10px;
}

.padrao {
  position: absolute;
  font-family: "FE-FONT";
  font-size: 70px;
  letter-spacing: 1px;
  top: 35%;
  left: 9%;
  line-height: 1;
  color: black !important;
}

.alert-md-local {
  background-color: #009688;
  border-color: #00695C;
  color: #fff;
}

.BGC_VERMELHO {
  border-radius: 10px;
  background-color: red;
}

.BGC_AZUL {
  border-radius: 10px;
  background-color: #3380ac;
}

.BGC_AMARELO {
  border-radius: 10px;
  background-color: #e78a2b;
}

.BGC_VERDE {
  border-radius: 10px;
  background-color: #09a880;
}

.BGC_CINZA {
  border-radius: 10px;
  background-color: #cbacb2;
}

.BGC_PRETO {
  border-radius: 10px;
  background-color: black;
}

.DIVCIRCLE {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

:root {
  --vermelho: #cb0001 !important;
  --azul: #2e58d1 !important;
  --amarelo: #ffa900 !important;
  --verde: #15ae53 !important;
  --cinza: #777777 !important;
  --preto: #000000;
}

.VERMELHO {
  color: var(--vermelho);
}

.AZUL {
  color: var(--azul);
}

.AMARELO {
  color: var(--amarelo);
}

.VERDE {
  color: var(--verde);
}

.CINZA {
  color: var(--cinza);
}

.PRETO {
  color: var(--preto);
}

.BKGVERMELHO {
  background-color: var(--vermelho);
}

.BKGAZUL {
  background-color: var(--azul);
}

.BKGAMARELO {
  background-color: var(--amarelo);
}

.BKGVERDE {
  background-color: var(--verde);
}

.BKGCINZA {
  background-color: var(--verde);
}

.BKGPRETO {
  background-color: var(--preto);
}

ngb-progressbar {
  margin-top: 10rem;
}

.asscenter {
  position: relative;
  text-align: center;
}

.assleft {
  position: relative;
  text-align: left;
}

.assright {
  position: relative;
  text-align: right;
}

.right_col {
  position: absolute;
  right: 10px;
}

.linha {
  border-bottom: 2px solid #000000;
}

.separador {
  border-bottom: 1px solid #000000;
}

.separadorCinza {
  border-bottom: 1px solid #d8d8d8;
}

.separadorCinza2 {
  border-bottom: 1px solid #e9e9e9;
}

.centeredMoto3 {
  position: absolute;
  top: 23%;
  left: 19%;
}

.centeredMoto {
  position: absolute;
  top: 25%;
  left: 20%;
}

.motoLetras2 {
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: FE-FONT;
  letter-spacing: 15px;
  font-size: 85px;
  line-height: 1;
}

.VERMELHO22 {
  color: #cb0001 !important;
}

.TXTBLUE {
  color: #09298a !important;
}

.TXTGREEN {
  color: #138d03 !important;
}

.TXTORANGE {
  color: #e26f2c;
}

.statuscolor-ok {
  background-color: #fdff8d;
}

.statuscolor-error {
  background-color: #e28888;
}

.tooltip > .tooltip-inner {
  background-color: #ffffff;
  color: black !important;
  border: 2px solid var(--primary-color);
}

@media screen and (max-width: 500px) {
  /* Whatever size you call "Mobile" */
  .containerplaca img {
    display: none;
  }

  .centered {
    visibility: hidden;
  }
}
.divcenter {
  position: absolute;
  bottom: 200px;
}

.m-ass {
  margin-top: 80px;
}

.footer {
  position: absolute;
  bottom: 2px;
  width: 100%;
}

.fs-placa {
  font-size: larger;
  color: #222222;
  font-weight: 800;
}

.imageLogo {
  width: 110px;
  height: 40px;
  background: url("src/assets/img/images/logo-black2.png") no-repeat;
  background-position: center center;
  margin: 0 auto;
  background-size: contain;
}

.datatable-body .empty-row {
  text-align: center !important;
  font-weight: bolder;
  font-size: large;
}

.datatable-header {
  font-weight: bolder;
  text-align: center;
}

.filterable {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.btn-circle-xs {
  padding: 0.0625rem 0.3125rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 500px;
  padding: 0;
  border: 0;
}
.btn-circle-xs > * {
  line-height: inherit !important;
}

.squareImg {
  position: relative;
  width: 75px;
  height: 75px;
  border-style: solid;
  border-width: 2px;
}

.squareSearch {
  position: relative;
  width: 75px;
  height: 20px;
  border-style: solid;
  border-width: 0px 2px 2px 2px;
}

.icon-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center !important;
  background-color: rgba(169, 169, 169, 0.3647058824);
  z-index: 1;
}

.img-center {
  width: 100%;
  height: 100%;
  z-index: 2;
}

.squareNewBtn {
  position: relative;
  width: 75px;
  height: 75px;
}

.squareFto {
  width: 160px;
  height: 150px;
  border-style: solid;
  border-width: 2px;
  border-color: gray;
}

.vid {
  width: 100%;
  height: 100%;
}

.squareBio {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 200%;
  border-style: solid;
  border-width: 3px;
  border-color: gray;
}

.div-bio {
  width: 100%;
  height: 100%;
  z-index: 2;
}

.img-center-bio {
  width: 70%;
  height: 80%;
}

.squareImgok {
  position: relative;
  width: 75px;
  height: 75px;
  border-style: groove;
  border-color: #4dbd74;
  border-width: 2px;
}

.squareNewBtnMini {
  position: relative;
  width: 30px;
  height: 30px;
}

.questionBtn {
  cursor: pointer;
  width: 13px;
  height: 13px;
  border-radius: 30px;
  background-color: #3e444b;
  border: none;
  font-size: 11px;
  font-weight: bold;
  color: white;
  padding-left: 3pt;
  padding-top: -1px;
}

.questionBtn2 {
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 30px;
  background-color: #3e444b;
  border: none;
  font-size: 13px;
  font-weight: bold;
  color: white;
}

.questionLabel {
  margin: 2px auto 0 30%;
  font-display: inherit;
  position: relative;
}

.bioImg {
  padding: 10px 10px 10px 20px;
  background-color: #00863c85;
  border-radius: 100%;
  border: 2px solid black;
}

.cursor_pointer {
  cursor: pointer;
}

.right_rounded {
  border-top-right-radius: 25% !important;
  border-bottom-right-radius: 25% !important;
}

.left_rounded {
  border-top-left-radius: 25% !important;
  border-bottom-left-radius: 25% !important;
}

.table_head {
  background-color: #b1b1b1;
}

.gtTable {
  width: 100%;
  border-collapse: collapse;
  border-width: 2px !important;
  border-radius: 20px;
  border-style: ridge;
  border-color: #a1a1a1;
  overflow-x: auto;
}
.gtTable th,
.gtTable td {
  padding: 10px;
  border-collapse: collapse;
  border-width: 1px;
  border-style: groove;
  border-color: #e2e2e269;
  text-align: center;
  vertical-align: middle;
}
.gtTable thead {
  background-color: #dfdfdf;
}
.gtTable tr:nth-child(even) {
  background-color: #f5f5f5;
}

.modal-custom {
  width: 60% !important;
  min-width: 265px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}

.gtTableLeft {
  width: 100%;
  border-collapse: collapse;
  border-width: 2px !important;
  border-radius: 20px;
  border-style: ridge;
  border-color: #a1a1a1;
  overflow-x: auto;
}
.gtTableLeft th,
.gtTableLeft td {
  padding: 10px;
  border-collapse: collapse;
  border-width: 1px;
  border-style: groove;
  border-color: #e2e2e269;
  text-align: left;
  vertical-align: middle;
}
.gtTableLeft thead {
  background-color: #dfdfdf;
}
.gtTableLeft tr:nth-child(even) {
  background-color: #f5f5f5;
}